import { Fragment, ReactElement } from "react";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import colors from "../../../colors";
import { SxProps } from "@mui/material";
import { TemplateVars } from "@CabComponents/CabTextTokenInput";


export interface CabTextTokenDisplayProps {
  children: string;
  templateVars: TemplateVars;
  chipSx?: SxProps;
}

const tokenPattern = /({{.+?}})/gi;

export const CabTextTokenDisplay = ({
  children, templateVars, chipSx,
}: CabTextTokenDisplayProps): ReactElement => {
  const textChunks = children.split(tokenPattern);

  return (
    <>
      {textChunks.map((text, i) => {
        const key = `${text}-${i}`;
        if (text.startsWith('{{') && text.endsWith('}}')) {
          const variable = text.slice(2, -2);
          return (
            <CabToggleChip
              key={key}
              label={templateVars[variable]
                ? templateVars[variable].replaceText
                : variable[0].toUpperCase() + variable.slice(1)}
              selected
              chipColor={colors.black400}
              sx={{ 
                marginLeft: 0, 
                marginRight: 0, 
                marginBottom: 0.25, 
                marginTop: 0, 
                borderWidth: 0, 
                paddingLeft: '6px', 
                paddingRight: '6px', 
                paddingTop: 0, 
                paddingBottom: '2px', 
                height: 20, 
                lineHeight: '20px', 
                ...chipSx 
              }}
              overrides={{ component: 'span' }}
            />
          );
        }

        return <Fragment key={key}>{text}</Fragment>;
      })}
    </>
  );
};

export const getTokenDisplayAsText = (tokenText: string, templateVars: TemplateVars) => {
  return tokenText.replaceAll(/{{(.+?)}}/gi, (substring, match) => (
    `[${templateVars[match] ? templateVars[match].replaceText : match[0].toUpperCase() + match.slice(1)}]`
  ));
};
